import { FetchGateway, NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { LivingBioMassCarbonMetricsResults } from '@/project/domain/repositories/RemoteSensing.repository'
import type BackendGateway from '@/project/infrastructure/gateways/Backend.gateway'
import type { FisProjectId } from '@/shared/domain/Ids'
import type AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'

export type Report = {
  reportName: string
  recordType: string
  status: string
  monitoringDate: string
  createdBy: string
  koboConfirmationLink: string
  placettesConfirmationLink: string | null
  recoveryRate: number | null
}

export type ReportsList = {
  remoteReports: Report[]
  fieldsReports: Report[]
  tropicalReports: Report[]
}

export type ReportApiResults = {
  id: string
  reports: ReportsList
}

export default class BackendHttpGateway extends FetchGateway implements BackendGateway {
  public baseUrl: string

  public constructor(
    _baseUrl: string,
    private readonly authenticationService: AuthenticationService,
  ) {
    super()
    this.baseUrl = _baseUrl
  }

  public async getMetrics(
    projectId: FisProjectId,
  ): Promise<LivingBioMassCarbonMetricsResults['metrics']> {
    const path = `/impact/remote-sensing/projects/${projectId}?populate=metrics`
    const accessToken = await this.authenticationService.getAccessToken()

    const results = await this.fetch<LivingBioMassCarbonMetricsResults>(path, {
      method: 'GET',
      accessToken: accessToken.token,
    })

    if (results instanceof NoResult) {
      return {}
    }

    return results.metrics
  }

  public async getReports(biomeId: string): Promise<ReportApiResults | NoResult> {
    const accessToken = await this.authenticationService.getAccessToken()
    return await this.fetch<ReportApiResults>(`/reportings/projects/${biomeId}`, {
      method: 'GET',
      accessToken: accessToken.token,
    })
  }
}
