import { defineStore } from 'pinia'
import type { AccessToken } from '@/authentication/infrastructure/services/Authentication.service'

interface State {
  accessToken: AccessToken | Record<string, never>
}

export const useAuthStore = defineStore('auth', {
  state: (): State => {
    return {
      accessToken: {} as AccessToken,
    }
  },

  actions: {
    setToken(token: AccessToken) {
      this.accessToken = token
    },
  },
})
