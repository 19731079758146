import { formatForDisplay } from '@/shared/presentation/formatters/Number.formatter'

export {}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    sum(): number
    deduplicate(): Array<T>
    isEmpty(): boolean
    isNotEmpty(): boolean
    first(): T | undefined
    last(): T | undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface String {
    toNumber(): number
  }

  interface Number {
    toPrettyString(): string
    isBefore(n: number, strict?: boolean): boolean
    isAfter(n: number, strict?: boolean): boolean
  }
}

Array.prototype.sum = function () {
  return this.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
}

Array.prototype.deduplicate = function () {
  return [...new Set(this)]
}

Array.prototype.isEmpty = function () {
  return this.length === 0
}

Array.prototype.isNotEmpty = function () {
  return this.length > 0
}

Array.prototype.last = function () {
  return this.at(-1)
}

Array.prototype.first = function () {
  return this.at(0)
}

String.prototype.toNumber = function () {
  return Number(this)
}

Number.prototype.toPrettyString = function () {
  return formatForDisplay(Number(this))
}

Number.prototype.isBefore = function (n: number, strict: boolean = false) {
  return strict ? this.valueOf() < n : this.valueOf() <= n
}

Number.prototype.isAfter = function (n: number, strict: boolean) {
  return strict ? this.valueOf() > n : this.valueOf() >= n
}
