export default class ProjectScenario {
  public value: number[]

  public constructor(
    private readonly bd1SpeciesLevelSupport: number[],
    private readonly bd2StandStructuralAndBiologicalDiversity: number[],
    private readonly bd3HabitatsAndResourcesMaintainedOrCreated: number[],
    private readonly bd4WorksImpact: number[],
  ) {
    this.value = this.bd4WorksImpact.map((bd4OrNaN, index) => {
      const bd1 = getNumberOrZeroIfNaN(this.bd1SpeciesLevelSupport[index])
      const bd2 = getNumberOrZeroIfNaN(this.bd2StandStructuralAndBiologicalDiversity[index])
      const bd3 = getNumberOrZeroIfNaN(this.bd3HabitatsAndResourcesMaintainedOrCreated[index])
      const bd4 = getNumberOrZeroIfNaN(bd4OrNaN)

      return bd1 + bd2 + bd3 + bd4
    })
  }
}

function getNumberOrZeroIfNaN(number: number): number {
  return isNaN(number) ? 0 : number
}
