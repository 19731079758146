import type { BiomeProjectId, FisProjectId } from '@/shared/domain/Ids'
import type { Coordinates } from '@/shared/domain/Coordinates'
import { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import { FullyFinancedByContributor } from '@/project/domain/value-objects/FullyFinancedByContributor'
import type { TotalBiodiversityImpact } from '@/shared/domain/value-objects/TotalBiodiversityImpact'
import type { FinancedBiodiversityImpact } from '@/shared/domain/value-objects/FinancedBiodiversityImpact'
import type { TreeDensity } from '@/project/domain/value-objects/TreeDensity'
import type { FinancedPercentage } from '@/shared/domain/value-objects/FinancedPercentage'
import type { ResidualFinancedPercentage } from '@/shared/domain/value-objects/ResidualFinancedPercentage'
import type { FinancedTrees } from '@/shared/domain/value-objects/FinancedTrees'
import type { FinancedArea } from '@/shared/domain/value-objects/FinancedArea'
import MonitoringCalendar from '@/project/domain/entities/MonitoringCalendar'
import type { SingleMetricData } from '@/project/domain/repositories/RemoteSensing.repository'
import BiodiversitySimulation from '@/project/domain/entities/BiodiversitySimulation'
import RemoteSensingIndexes from '@/project/domain/entities/RemoteSensingIndexes'
import { CarbonSimulation } from '@/shared/domain/value-objects/CarbonSimulation'
import type { ReportsList } from '@/project/infrastructure/gateways/Backend.httpgateway'
import type { Hectare } from '@/shared/domain/value-objects/Units'

export type ProjectLocation = {
  city: string
  country: string
  continent: string
  coordinates: Coordinates
}

export type Polygons = {
  coordinates: Coordinates[]
}[]

export type Species = {
  name: string
  scientificName: string
  repartition: number
}[]

export type ProjectType =
  | 'CARBON_PROJECT_PURCHASE_CREDIT'
  | 'ASSISTED_NATURAL_REGENERATION_EU_US'
  | 'CARBON_PROJECT_DEVELOPMENT'
  | 'PLANTATION_PROJECT_EU_US'
  | 'URBAN_FOREST_PROJECT'
  | 'ECOSYSTEM_RESTORATION_PROJECT_OUTSIDE_EU_US'
  | 'LABEL_BAS_CARBON_PROJECT'
  | 'REGENERATIVE_AGRICULTURE'
  | 'UNKNOWN'

export class DetailedProject {
  public constructor(
    public readonly biomeId: BiomeProjectId,
    public readonly fisId: FisProjectId,
    public readonly type: ProjectType,
    public readonly plantingSeason: string,
    public readonly location: ProjectLocation,
    public readonly treeCount: number,
    public readonly area: Hectare,
    public readonly typologies: { main: string; secondaries: string[] },
    public readonly polygons: Polygons,
    public readonly species: Species,
    public readonly implementorName: string | null,
    public readonly speciesDestination: string[],
    private readonly totalBiodiversityImpact: TotalBiodiversityImpact | null,
    private readonly financedBiodiversityImpact: FinancedBiodiversityImpact | null,
    private readonly fullyFinancedByContributor: FullyFinancedByContributor,
    private readonly financedArea: FinancedArea,
    private readonly financedTrees: FinancedTrees,
    private readonly treeDensity: TreeDensity,
    private readonly financedPercentage: FinancedPercentage,
    private readonly residualFinancedPercentage: ResidualFinancedPercentage,
    private readonly financedRatio: FinancedRatio,
    private readonly carbonSimulation: CarbonSimulation | null,
    public readonly biodiversitySimulation: BiodiversitySimulation | null,
    private readonly monitoringCalendar: MonitoringCalendar | null,
    public readonly remoteSensing: RemoteSensingIndexes | null,
    private readonly livingBioMassCarbonMetrics: SingleMetricData[] | null,
    public readonly reportings: ReportsList | null,
  ) {}

  public static hydrate(rawProject: DetailedProject): DetailedProject {
    return new DetailedProject(
      rawProject.biomeId,
      rawProject.fisId,
      rawProject.type,
      rawProject.plantingSeason,
      rawProject.location,
      rawProject.treeCount,
      rawProject.area,
      rawProject.typologies,
      rawProject.polygons,
      rawProject.species,
      rawProject.implementorName,
      rawProject.speciesDestination,
      rawProject.totalBiodiversityImpact,
      rawProject.financedBiodiversityImpact,
      rawProject.fullyFinancedByContributor,
      rawProject.financedArea,
      rawProject.financedTrees,
      rawProject.treeDensity,
      rawProject.financedPercentage,
      rawProject.residualFinancedPercentage,
      rawProject.financedRatio,
      rawProject.carbonSimulation
        ? new CarbonSimulation(
            rawProject.carbonSimulation.simulationValues,
            rawProject.carbonSimulation.startingYear,
            rawProject.carbonSimulation.discount,
            rawProject.carbonSimulation.financedRatio,
          )
        : null,
      rawProject.biodiversitySimulation
        ? BiodiversitySimulation.hydrate(rawProject.biodiversitySimulation)
        : null,
      rawProject.monitoringCalendar
        ? MonitoringCalendar.hydrate(
            rawProject.monitoringCalendar,
            rawProject.type,
            rawProject.reportings,
          )
        : null,
      rawProject.remoteSensing ? RemoteSensingIndexes.hydrate(rawProject.remoteSensing) : null,
      rawProject.livingBioMassCarbonMetrics,
      rawProject.reportings || null,
    )
  }

  /* -- Climate Simulations -- */

  public containsCarbonSimulation(): boolean {
    return this.carbonSimulation !== null
  }

  public getFinancedSequesteredCarbonOver30Years(): number[] {
    return this.carbonSimulation?.financedValues() ?? []
  }

  public getSequesteredCarbonOver30Years(): number[] {
    return this.carbonSimulation?.rawValues() ?? []
  }

  public getTotalSequesteredCarbon(): number {
    return this.carbonSimulation?.raw() ?? 0
  }

  public getFinancedSequesteredCarbon(): number {
    return this.carbonSimulation?.financed() ?? 0
  }

  public getLivingBioMassCarbonMetrics(): SingleMetricData[] {
    return this.livingBioMassCarbonMetrics || []
  }

  /* -- Biodiversity Simulations -- */

  public getTotalBiodiversityImpactScore(): number {
    return this.totalBiodiversityImpact ? this.totalBiodiversityImpact.value : 0
  }

  public getFinancedBiodiversityImpact(): number {
    return this.financedBiodiversityImpact ? this.financedBiodiversityImpact.value : 0
  }

  /* -- Area -- */

  public getFinancedArea(): number {
    return this.financedArea.value
  }

  /* -- Trees -- */

  public getFinancedTrees(): number {
    return this.financedTrees.value
  }

  public getTreeDensity(): number {
    return this.treeDensity.value
  }

  /* -- Financement -- */

  public getFinancedPercentage(): number {
    return this.financedPercentage.value
  }

  public getResidualFinancementPercentage(): number {
    return this.residualFinancedPercentage.value
  }

  public hasContributorFullyFinancedProject(): boolean {
    return this.fullyFinancedByContributor.value
  }

  /* -- MonitoringCalendar -- */
  public isMonitoringFilled(): boolean {
    return this.monitoringCalendar !== null
  }

  public getMonitoringCalendar(): MonitoringCalendar {
    return this.monitoringCalendar!
  }

  public getReportings(): ReportsList | null {
    return this.reportings
  }
}
