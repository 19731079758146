import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { ThisProjectSummaryViewModel } from '@/project/presentation/view-models/ThisProjectSummary.viewmodel'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhatIsThisProjectSummaryPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public async handle(): Promise<ViewModelContainer<ThisProjectSummaryViewModel>> {
    const detailedProject = this.projectRepository.getProject()
    return ViewModelContainer.WithContent({
      name: detailedProject.location.city,
      country: detailedProject.location.country,
      date: detailedProject.plantingSeason.replace('-', ' - '),
      implementer: detailedProject.implementorName ? detailedProject.implementorName : '',
      latLng:
        detailedProject.location.coordinates.latitude +
        ' , ' +
        detailedProject.location.coordinates.longitude,
      webSiteUrl: `https://www.reforestaction.com/projets/${detailedProject.fisId}`,
    })
  }
}
