import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import fr from '@/locales/fr.json'

export enum LocaleCode {
  ENGLISH = 'en',
  FRENCH = 'fr',
}

function extractLocaleCodeFromNavigator() {
  const { language } = navigator
  if (language.indexOf('-') >= 0) {
    return language.split('-')[0]
  }

  return language
}

export const i18n = createI18n({
  legacy: false,
  locale: extractLocaleCodeFromNavigator() || LocaleCode.ENGLISH,
  fallbackLocale: LocaleCode.ENGLISH,
  messages: { en, fr },
})
