import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { ThisProjectTypologiesViewModel } from '@/project/presentation/view-models/ThisProjectTypologies.viewmodel'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhatIsThisProjectTypologiesPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<ThisProjectTypologiesViewModel> {
    const project = this.projectRepository.getProject()
    return ViewModelContainer.WithContent({
      main: project.typologies.main,
      secondaries: project.typologies.secondaries,
    })
  }
}
