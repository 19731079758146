import { type _GettersTree, defineStore, type StateTree } from 'pinia'
import { SummarizedProject } from '@/contributor/domain/entities/SummarizedProject'
import type { Country, Season } from '@/contributor/domain/entities/Contributor'

export type CountryWithCountPerSeason = { country: Country; season: Season; count: Count }
export type Count = number & { _: 'count' }

interface State extends StateTree {
  isLoading: boolean
  projects: SummarizedProject[]
}

interface Getters extends _GettersTree<State> {}

interface Actions {
  initialize: (projects: SummarizedProject[]) => void
}

export const useContributorStore = defineStore<'contributor', State, Getters, Actions>({
  id: 'contributor',

  state: () => ({
    isLoading: true,
    projects: [],
  }),

  getters: {
    countProjects(state) {
      return (
        filters: {
          season?: string
          carbonSimulations?: boolean
          carbonSimulatedYear?: number
          biodiversitySimulations?: boolean
          biodiversitySimulatedYear?: number
        } = {},
      ) => {
        if (Object.keys(filters).length === 0) {
          return state.projects.length
        }

        const { length } = state.projects.filter((p) => {
          const applySeasonFilter = filters.season ? p.plantingSeason === filters.season : true
          const applyCarbonSimulationsFilter = filters.carbonSimulations
            ? p.containsCarbonSimulations()
            : true
          const applyYearCarbonSimulationsFilter = filters.carbonSimulatedYear
            ? p.isSimulatedForCarbonAtYear(filters.carbonSimulatedYear)
            : true
          const applyBiodiversitySimulationsFilter = filters.biodiversitySimulations
            ? p.containsBiodiversitySimulation()
            : true
          const applyYearBiodiversitySimulationsFilter = filters.biodiversitySimulatedYear
            ? p.isSimulatedForBiodiversityAtYear(filters.biodiversitySimulatedYear)
            : true
          return (
            applySeasonFilter &&
            applyCarbonSimulationsFilter &&
            applyYearCarbonSimulationsFilter &&
            applyBiodiversitySimulationsFilter &&
            applyYearBiodiversitySimulationsFilter
          )
        })

        return length
      }
    },

    countFinancedSimulatedSequesteredCarbon(state) {
      return (filters: { year?: number } = {}) => {
        return state.projects
          .map((p) => p.countFinancedSimulatedSequesteredCarbon(filters.year))
          .sum()
      }
    },

    countFinancedSimulatedBiodiversityImpact(state) {
      return (filters: { year?: number } = {}) => {
        return (state as State).projects
          .map((p) => p.countFinancedSimulatedBiodiversityImpact(filters.year))
          .sum()
      }
    },

    measureTotalArea(state) {
      return state.projects.map((p) => p.getFinancedArea()).sum()
    },

    countTrees(state) {
      return state.projects.map((p) => p.getFinancedTrees()).sum()
    },

    listProjectsSeasons(state) {
      return state.projects
        .map((p) => p.plantingSeason)
        .deduplicate()
        .sort()
    },

    listContributedCountries(state) {
      return (filters: { season?: string } = {}) => {
        const NOT_FOUND_INDEX = -1
        return state.projects
          .filter((p) => (filters.season ? p.plantingSeason === filters.season : true))
          .map((sp) => ({ country: sp.location.country, season: sp.plantingSeason }))
          .reduce<CountryWithCountPerSeason[]>(
            (countryWithCountPerSeasonList, { country, season }) => {
              const indexForMatchingSeasonAndCountry = countryWithCountPerSeasonList.findIndex(
                (cwc) => cwc.country === country && cwc.season === season,
              )

              if (indexForMatchingSeasonAndCountry === NOT_FOUND_INDEX) {
                const newCountryWithCountAndSeason = { country, season, count: 1 as Count }
                return [...countryWithCountPerSeasonList, newCountryWithCountAndSeason]
              }

              const match = countryWithCountPerSeasonList[indexForMatchingSeasonAndCountry]
              match.count = (match.count + 1) as Count

              return countryWithCountPerSeasonList
            },
            [],
          )
      }
    },

    listSimulatedYearsForCarbon(state) {
      return state.projects
        .flatMap((p) => p.listSimulatedYearsForCarbon())
        .deduplicate()
        .sort()
    },

    listSimulatedYearsForBiodiversity(state) {
      return state.projects
        .flatMap((p) => p.listSimulatedYearsForBiodiversity())
        .deduplicate()
        .sort()
    },

    listProjectsWithCarbonSimulation(state) {
      return (filters: { year?: number } = {}) =>
        state.projects.filter((p) => {
          const containsCarbonSimulation = p.containsCarbonSimulations()
          const applyYearFilter = filters.year ? p.isSimulatedForCarbonAtYear(filters.year) : true
          return containsCarbonSimulation && applyYearFilter
        })
    },

    listProjectsWithBiodiversitySimulation(state) {
      return (filters: { year?: number } = {}) =>
        state.projects.filter((p) => {
          const containsBiodiversitySimulation = p.containsBiodiversitySimulation()
          const applyYearFilter = filters.year
            ? p.isSimulatedForBiodiversityAtYear(filters.year)
            : true
          return containsBiodiversitySimulation && applyYearFilter
        })
    },
  },

  actions: {
    initialize(projects: SummarizedProject[]) {
      this.projects = projects
      this.isLoading = false
    },
  },
})
