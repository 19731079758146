import { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import type {
  AttributionHttp,
  FisHttpClient,
  FisMediaClientList,
  FisProject,
} from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { BiodiversitySimulationApiResults } from '@/shared/infrastructure/gateways/monolith.gateway'
import Contributor from '@/contributor/domain/entities/Contributor'
import { Description } from '@/shared/domain/value-objects/Descriptions'
import { ContributorTotalFinancedSequesteredCarbon } from '@/contributor/domain/value-objects/ContributorTotalFinancedSequesteredCarbon'
import { SummarizedProject } from '@/contributor/domain/entities/SummarizedProject'
import type {
  CarbonSimulationForProject,
  FisProjectMediaList,
} from '@/contributor/infrastructure/repositories/Contributor.httprepository'
import { TotalBiodiversityImpact } from '@/shared/domain/value-objects/TotalBiodiversityImpact'
import { FinancedBiodiversityImpact } from '@/shared/domain/value-objects/FinancedBiodiversityImpact'
import { FinancedArea } from '@/shared/domain/value-objects/FinancedArea'
import { FinancedTrees } from '@/shared/domain/value-objects/FinancedTrees'
import { FinancedPercentage } from '@/shared/domain/value-objects/FinancedPercentage'
import ContributorTotalFinancedBiodiversityImpact from '@/contributor/domain/value-objects/ContributorTotalFinancedBiodiversityImpact'
import type { ProjectBiodiversitySimulation } from '@/shared/domain/value-objects/ProjectBiodiversitySimulation'
import { CarbonSimulation, StartingYear } from '@/shared/domain/value-objects/CarbonSimulation'
import { CarbonSimulationDiscount } from '@/shared/domain/value-objects/CarbonSimulationDiscount'
import type { BiomeProjectId } from '@/shared/domain/Ids'

const DEFAULT_COORDINATES = 0
const DEFAULT_TREE_COUNT = 1

export class ContributorFactory {
  public static toContributor(
    client: FisHttpClient,
    clientMedias: FisMediaClientList,
    projects: FisProject[],
    projectsMedias: FisProjectMediaList[],
    rawCarbonSimulations: CarbonSimulationForProject[],
    biodiversitySimulations: BiodiversitySimulationApiResults[],
  ): Contributor {
    const clientMedia = this.getClientMedia(clientMedias)

    const validatedAttributions = this.getValidatedAttributions(client)

    const financedRatiosByProject = ContributorFactory.buildFinancedRatioByProject(
      projects,
      validatedAttributions,
    )

    const carbonSimulationByProject = ContributorFactory.buildCarbonSimulationByProject(
      projects,
      rawCarbonSimulations,
      financedRatiosByProject,
    )

    const allProjectsBiodiversitySimulations: ProjectBiodiversitySimulation[] =
      this.getAllProjectsBiodiversitySimulations(
        biodiversitySimulations,
        validatedAttributions,
        projects,
      )

    return new Contributor(
      client.name,
      clientMedia ? clientMedia.url : '',
      new Description(client.description),
      new ContributorTotalFinancedSequesteredCarbon(Array.from(carbonSimulationByProject.values())),
      new ContributorTotalFinancedBiodiversityImpact(allProjectsBiodiversitySimulations),
      projects.map((fisProject) => {
        return ContributorFactory.toProject(
          fisProject,
          projectsMedias.find((p) => p.projectFisId === fisProject.id),
          financedRatiosByProject.get(fisProject.idBiome)!,
          carbonSimulationByProject.get(fisProject.idBiome),
          biodiversitySimulations.filter((bs) => bs.projectId === fisProject.id),
        )
      }),
    )
  }

  private static buildFinancedRatioByProject(
    projects: FisProject[],
    validatedAttributions: AttributionHttp[],
  ) {
    return projects.reduce<Map<BiomeProjectId, FinancedRatio>>((map, project) => {
      if (!map.has(project.idBiome)) {
        const financedRatio = new FinancedRatio(
          validatedAttributions
            .filter((va) => va.idProject === project.idBiome)
            .map((attr) => attr.nbStockApprouved)
            .sum(),
          projects
            .filter((p) => p.idBiome === project.idBiome)
            .map((fp) => fp.numberOfTrees)
            .sum(),
        )
        return map.set(project.idBiome, financedRatio)
      }
      return map
    }, new Map())
  }

  private static getClientMedia(clientMedias: FisMediaClientList) {
    return clientMedias.data.find((d) =>
      d.clientMedias.find((cm) => cm.webTags.includes('LOGO_COLOR')),
    )
  }

  private static getValidatedAttributions(client: FisHttpClient) {
    return client.attributions.filter((attribution) => attribution.status === 'Validé')
  }
  private static getAllProjectsBiodiversitySimulations(
    biodiversitySimulationResults: BiodiversitySimulationApiResults[],
    validatedAttributions: AttributionHttp[],
    projects: FisProject[],
  ) {
    return biodiversitySimulationResults.map((bs) => {
      const project = projects.find((p) => p.id === bs.projectId)!
      const financedRatio = new FinancedRatio(
        validatedAttributions
          .filter((attr) => attr.idProject === project.idBiome)
          .map((attr) => attr.nbStockApprouved)
          .sum(),
        projects
          .filter((p) => p.idBiome === project.idBiome)
          .map((fp) => fp.numberOfTrees)
          .sum(),
      )
      return <ProjectBiodiversitySimulation>{
        financedRatio,
        area: project.area,
        bd1SpeciesLevelSupport: bs.results.bd1SpeciesLevelSupport,
        bd2StandStructuralAndBiologicalDiversity:
          bs.results.bd2StandStructuralAndBiologicalDiversity,
        bd3HabitatsAndResourcesMaintainedOrCreated:
          bs.results.bd3HabitatsAndResourcesMaintainedOrCreated,
        bd4WorksImpact: bs.results.bd4WorksImpact,
      }
    })
  }

  private static toProject(
    fisProject: FisProject,
    projectMedias: FisProjectMediaList | undefined,
    financedRatio: FinancedRatio,
    carbonSimulation: CarbonSimulation | undefined,
    biodiversitySimulations: BiodiversitySimulationApiResults[],
  ) {
    const totalBiodiversityImpact = ContributorFactory.getTotalBiodiversityImpact(
      fisProject,
      biodiversitySimulations,
    )

    return new SummarizedProject(
      fisProject.id,
      fisProject.idBiome,
      fisProject.name.replace(/\s+/g, ' '),
      this.getHeaderMedia(projectMedias),
      {
        city: fisProject.city,
        country: fisProject.country,
        continent: fisProject.continent,
        longitude: fisProject.centroidLongitude ?? DEFAULT_COORDINATES,
        latitude: fisProject.centroidLatitude ?? DEFAULT_COORDINATES,
      },
      fisProject.plantingSeason,
      {
        main: fisProject.mainTypology,
        secondaries: fisProject.secondaryTypology ? fisProject.secondaryTypology.split(';') : [],
      },
      fisProject.area,
      fisProject.numberOfTrees ?? DEFAULT_TREE_COUNT,
      carbonSimulation ?? null,
      biodiversitySimulations.map((bs) => bs.results),
      financedRatio,
      new FinancedPercentage(financedRatio),
      totalBiodiversityImpact,
      totalBiodiversityImpact
        ? new FinancedBiodiversityImpact(totalBiodiversityImpact, financedRatio)
        : null,
      new FinancedTrees(fisProject.numberOfTrees ?? DEFAULT_TREE_COUNT, financedRatio),
      new FinancedArea(fisProject.area, financedRatio),
    )
  }

  private static getTotalBiodiversityImpact(
    fisProject: FisProject,
    biodiversitySimulations: BiodiversitySimulationApiResults[],
  ): TotalBiodiversityImpact | null {
    if (biodiversitySimulations.length === 0) {
      return null
    }

    const biodiversitySimulationResults = biodiversitySimulations.find(
      (bs) => bs.projectId === fisProject.id,
    )
    if (!biodiversitySimulationResults) {
      return null
    } else {
      return new TotalBiodiversityImpact(
        fisProject.area,
        biodiversitySimulationResults.results.bd1SpeciesLevelSupport,
        biodiversitySimulationResults.results.bd2StandStructuralAndBiologicalDiversity,
        biodiversitySimulationResults.results.bd3HabitatsAndResourcesMaintainedOrCreated,
        biodiversitySimulationResults.results.bd4WorksImpact,
      )
    }
  }

  private static getHeaderMedia(projectMedias: FisProjectMediaList | undefined): string {
    if (!projectMedias) return ''

    const headerMedia = projectMedias.data.find((p) =>
      p.projectMedias.find(
        (m) => m.idProject === projectMedias.projectFisId && m.webTags.includes('HEADER_MEDIA'),
      ),
    )

    if (!headerMedia) return ''

    return headerMedia.url
  }

  private static buildCarbonSimulationByProject(
    projects: FisProject[],
    rawCarbonSimulations: CarbonSimulationForProject[],
    financedRatiosByProject: Map<BiomeProjectId, FinancedRatio>,
  ) {
    return projects.reduce<Map<BiomeProjectId, CarbonSimulation>>((map, project) => {
      const financedRatio = financedRatiosByProject.get(project.idBiome)
      const carbonSimulationHasNotBeenAddedYet = !map.has(project.idBiome)

      if (carbonSimulationHasNotBeenAddedYet && financedRatio) {
        const simulationValues =
          rawCarbonSimulations.find((rcs) => rcs.projectBiomeId === project.idBiome)?.results
            .totalCredits ?? []
        const carbonSimulation = new CarbonSimulation(
          simulationValues,
          StartingYear.fromPlantingSeason(project.plantingSeason),
          new CarbonSimulationDiscount(),
          financedRatio,
        )

        map.set(project.idBiome, carbonSimulation)
      }
      return map
    }, new Map())
  }
}
