import { Clock, type Iso8601, type Milliseconds, type Timestamp } from '@/shared/domain/Clock'
import router from '@/shared/infrastructure/router'

export default class StorageService {
  public get<T>(key: string): T | NoItemFound {
    const rawItem = window.localStorage.getItem(key)
    if (!rawItem) return NoItemFound.keyNotFound(key)

    let rawCachedContent: SerializedCachedContent<T>
    try {
      rawCachedContent = JSON.parse(rawItem) as SerializedCachedContent<T>
    } catch (e) {
      router.replace({ name: 'login', query: { type: 'unknown' } })
      return NoItemFound.parsingError(key)
    }

    const cachedContent = CachedContent.hydrate<T>(
      rawCachedContent.content,
      rawCachedContent.expiresAt,
    )

    return cachedContent.isStale() ? NoItemFound.staleContent(key) : cachedContent.content
  }

  public set(key: string, value: any, ttlInMs?: Milliseconds): void {
    const content = ttlInMs ? CachedContent.expiresAt(value, ttlInMs) : CachedContent.noCache(value)
    window.localStorage.setItem(key, JSON.stringify(content))
  }

  public removeAll(): void {
    window.localStorage.clear()
  }
}

interface SerializedCachedContent<T> {
  content: T
  expiresAt: Iso8601 | null
}

class CachedContent<T> implements SerializedCachedContent<T> {
  private constructor(
    public readonly content: T,
    public readonly expiresAt: Iso8601 | null,
  ) {}

  public static expiresAt(content: any, ttl: Milliseconds): CachedContent<any> {
    const timestamp = (Clock.nowTimestamp() + ttl) as Timestamp
    const expiresAt = Clock.fromTimestampToIso(timestamp)
    return new CachedContent(content, expiresAt)
  }

  public static hydrate<T>(content: T, expiresAt: Iso8601 | null): CachedContent<T> {
    return new CachedContent<T>(content, expiresAt)
  }

  public static noCache(content: any): CachedContent<any> {
    const NO_CACHE = null
    return new CachedContent(content, NO_CACHE)
  }

  public isStale(): boolean {
    if (this.expiresAt === null) return false

    const expiresAt = Clock.fromIsoToTimestamp(this.expiresAt)
    const now = Clock.nowTimestamp()

    return expiresAt < now
  }
}

export class NoItemFound {
  private constructor(message: string) {
    console.warn(message)
  }

  public static keyNotFound(key: string): NoItemFound {
    return new NoItemFound(`No item found in localStorage when looking for key "${key}"`)
  }

  public static staleContent(key: string): NoItemFound {
    return new NoItemFound(`Key "${key}" was found but its content is stale`)
  }

  public static parsingError(key: string): NoItemFound {
    return new NoItemFound(`Key "${key}" was found but parsing failed`)
  }
}
