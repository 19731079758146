import type {
  FisZoneList,
  FisPlotList,
  FisLocationList,
} from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { AccessToken } from '@/authentication/infrastructure/services/Authentication.service'
import StorageService, { NoItemFound } from '@/shared/infrastructure/services/Storage.service'
import type { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { FisProjectId } from '@/shared/domain/Ids'
import type { Coordinates } from '@/shared/domain/Coordinates'

export type Polygons = { id: string; coordinates: Coordinates[] }[]

export default class PolygonService {
  public constructor(
    private readonly backofficeGateway: BackofficeGateway,
    private readonly storageService: StorageService,
  ) {}

  public async getPolygons(idProject: FisProjectId): Promise<Polygons | NoResult> {
    const accessToken = this.storageService.get<AccessToken>('accessToken')
    if (accessToken instanceof NoItemFound) return []

    const plotsResponse = await this.backofficeGateway.getParcels(accessToken.token, idProject)
    if (plotsResponse instanceof Error) {
      return plotsResponse
    }

    if (plotsResponse.data.length > 0) {
      return this.getCoordinatesFromParcels(plotsResponse)
    }

    const zonesResponse = await this.backofficeGateway.getZones(accessToken.token, idProject)
    if (zonesResponse instanceof Error) {
      return zonesResponse
    }

    if (zonesResponse.data.length > 0) {
      return this.getCoordinatesFromZones(zonesResponse)
    }

    const locationsResponse = await this.backofficeGateway.getLocations(
      accessToken.token,
      idProject,
    )
    if (locationsResponse instanceof Error) {
      return locationsResponse
    }

    if (locationsResponse.data.length > 0) {
      return this.getCoordinatesFromLocations(locationsResponse)
    }

    return []
  }

  private getCoordinatesFromParcels(plotsResponse: FisPlotList): Polygons {
    return plotsResponse.data.map((plot) => ({
      id: plot.idFis,
      coordinates: plot.coordinates.map((coordinate) => this.mapFromFisCoordinates(coordinate)),
    }))
  }

  private getCoordinatesFromZones(zonesResponse: FisZoneList): Polygons {
    return zonesResponse.data.map((zone) => ({
      id: zone.idFis,
      coordinates: zone.coordinates.map((coordinate) => this.mapFromFisCoordinates(coordinate)),
    }))
  }

  /**
   * Oui, vous avez bien lu : on met la latitude dans la longitude et inversement.
   * En effet, l'API à partir de laquelle on récupère ces données a inversé ces deux valeurs...
   */
  private mapFromFisCoordinates(coordinate: Coordinates): Coordinates {
    return { latitude: coordinate.longitude, longitude: coordinate.latitude }
  }

  private getCoordinatesFromLocations(locationsResponse: FisLocationList): Polygons {
    return locationsResponse.data.map((location) => ({
      id: location.idFis,
      coordinates: [{ latitude: location.centroidLat, longitude: location.centroidLng }],
    }))
  }
}
