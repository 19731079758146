import { Unit } from '@/shared/domain/value-objects/Units'

export function getTranslationKeyForUnit(unit: Unit): string {
  const commonPath = 'shared.unit.'
  switch (unit) {
    case Unit.HECTARE:
      return commonPath + 'hectare'
    case Unit.TONS_CO2_EQUIVALENT:
      return commonPath + 'tonsCO2Equivalent'
    case Unit.BIODIVERSITY_RESTORATION_HECTARE_EQUIVALENT_REFERENCE_ECOSYSTEM:
      return commonPath + 'biodiversityRestorationHectareEquivalentReferenceEcosystem'
    case Unit.SPECIES:
      return commonPath + 'species'
    case Unit.TREES:
      return commonPath + 'trees'
    case Unit.TREES_PER_HECTARE:
      return commonPath + 'treesPerHectare'
    case Unit.SCORE:
      return commonPath + 'score'
  }
}
