export type Iso8601 = string & { _: 'iso8601' }
export type Timestamp = number & { _: 'timestamp' }
export type Milliseconds = number & { _: 'milliseconds' }

export class Clock {
  public static readonly ONE_SECOND: Milliseconds = 1000 as Milliseconds
  public static readonly ONE_MINUTE: Milliseconds = (Clock.ONE_SECOND * 60) as Milliseconds
  public static readonly THIRTY_MINUTES: Milliseconds = (Clock.ONE_MINUTE * 30) as Milliseconds

  public static nowTimestamp(): Timestamp {
    return Date.now() as Timestamp
  }

  public static fromTimestampToIso(timestamp: Timestamp): Iso8601 {
    return new Date(timestamp).toISOString() as Iso8601
  }

  public static fromIsoToTimestamp(iso: Iso8601): Timestamp {
    return new Date(iso).getTime() as Timestamp
  }
}
