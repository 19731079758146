import type { CarbonSimulation } from '@/shared/domain/value-objects/CarbonSimulation'

export class ContributorTotalFinancedSequesteredCarbon {
  public readonly value: number

  public constructor(projectsCarbonSimulations: CarbonSimulation[]) {
    const totalFinanced = projectsCarbonSimulations.map((cs) => cs.financed()).sum()
    this.value = totalFinanced.toFixed(2).toNumber()
  }
}
