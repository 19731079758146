import { createApp } from 'vue'
import '@ds/styles/index.css'
import '../public/assets/main.css'
import './shared/override'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './shared/infrastructure/router'
import env from '@/shared/env'
import { i18n } from '@/locales/i18n'
import 'tippy.js/dist/tippy.css'
import VueTippy from 'vue-tippy'
import { createPinia } from 'pinia'

const app = createApp(App)
const pinia = createPinia()

if (env.isProd && !env.isLocalhost) {
  Sentry.init({ app, dsn: env.sentry.url })
}

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(VueTippy, {
  component: 'tippy',
  defaultProps: {
    placement: 'bottom',
    allowHTML: false,
  },
})

app.mount('#app')
