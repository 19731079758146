import type { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import type { Hectare } from '@/shared/domain/value-objects/Units'

export class FinancedArea {
  public value: Hectare

  public constructor(totalArea: Hectare, financedRatio: FinancedRatio) {
    this.value = (totalArea * financedRatio.value) as Hectare
  }
}
