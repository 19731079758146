import { Unit } from '@/shared/domain/value-objects/Units'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { AreaForThisProjectViewModel } from '@/project/presentation/view-models/AreaForThisProject.viewmodel'

import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhatAreaDoesThisProjectCoverPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<AreaForThisProjectViewModel> {
    const project = this.projectRepository.getProject()

    return ViewModelContainer.WithContent({
      areaTakenByTheClient: project.getFinancedArea().toPrettyString(),
      totalArea: project.area.toPrettyString(),
      unit: Unit.HECTARE,
    })
  }
}
