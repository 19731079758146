type WithUrl = { url: string }
type WithApiKey = { apiKey: string }

type Environment = {
  baseUrl: string
  carbonSimulator: WithUrl
  backoffice: WithUrl
  isDev: boolean
  isProd: boolean
  isLocalhost: boolean
  isMock: boolean
  mapBox: WithUrl & WithApiKey
  sentry: WithUrl
  remoteSensing: WithUrl
  backend: WithUrl
}

const environment: Environment = {
  baseUrl: import.meta.env.BASE_URL,
  carbonSimulator: { url: get('VITE_CARBON_SIMULATOR_API_URL') },
  isDev: import.meta.env.DEV,
  isProd: import.meta.env.PROD,
  isLocalhost: isLocalhost(),
  isMock: import.meta.env.MODE === 'mock',
  backoffice: {
    url: get('VITE_FIS_API_URL'),
  },
  mapBox: { url: get('VITE_MAPBOX_URL'), apiKey: get('VITE_MAPBOX_API_KEY') },
  sentry: { url: get('VITE_SENTRY_DSN') },
  remoteSensing: {
    url: get('VITE_REMOTE_SENSING_URL'),
  },
  backend: {
    url: get('VITE_BACK_END_URL'),
  },
}

export default environment

function get(key: string): string {
  return import.meta.env[key]
}

function isLocalhost() {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  )
}
