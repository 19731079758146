import type { FisProjectId } from '@/shared/domain/Ids'
import type { ColorHexa } from 'design-system/src/styles/constants'

export type SnapshotIndex = {
  id: string
  snapshotId: string
  url: string
  minimum: number
  mean: number
  maximum: number
}

export type ProjectSnapshot = {
  id: string
  projectId: FisProjectId
  startDate: string
  endDate: string
  url: string
  ndvis: SnapshotIndex[]
  ndmis: SnapshotIndex[]
  year: number
  isValidated: number
}
export type IndexForAYear = {
  year: number
  minimum: number
  mean: number
  maximum: number
  url: string
}

export type SpectralSpeciesProperties = {
  id: string
  snapshotId: string
  url: string
  year: string
  optimalClasses: number
}

export interface SpectralSpeciesContent {
  spectralSpecies: SpectralSpeciesProperties[]
  years: string[]
  chart: {
    dataset: {
      labels: string[]
      datasets: {
        type: string
        data: number[]
        borderColor: ColorHexa
      }[]
    }
    options: any
  }
}

export default class RemoteSensingIndexes {
  public readonly ndviRangeValue: [number, number] = [0, 1]
  public readonly ndmiRangeValue: [number, number] = [-1, 1]

  public constructor(
    public readonly id: string,
    public readonly fisId: FisProjectId,
    public readonly snapshots: ProjectSnapshot[],
    public readonly spectralSpecies: SpectralSpeciesProperties[],
  ) {}

  public static hydrate(rawRemoteSensing: RemoteSensingIndexes): RemoteSensingIndexes {
    return new RemoteSensingIndexes(
      rawRemoteSensing.id,
      rawRemoteSensing.fisId,
      rawRemoteSensing.snapshots,
      rawRemoteSensing.spectralSpecies,
    )
  }

  public getValidatedNdvisForAYear(): IndexForAYear[] {
    if (this.snapshots.every((s) => s.ndvis.length === 0)) return []
    return this.getValidatedSnapshots().map((s) => {
      return <IndexForAYear>{
        year: s.year,
        minimum: s.ndvis[0].minimum,
        mean: s.ndvis[0].mean,
        maximum: s.ndvis[0].maximum,
        url: s.ndvis[0].url,
      }
    })
  }

  public getValidatedNdmisForAYear(): IndexForAYear[] {
    if (this.snapshots.every((s) => s.ndmis.length === 0)) return []
    return this.getValidatedSnapshots().map(
      (s) =>
        <IndexForAYear>{
          year: s.year,
          minimum: s.ndmis[0].minimum,
          mean: s.ndmis[0].mean,
          maximum: s.ndmis[0].maximum,
          url: s.ndmis[0].url,
        },
    )
  }

  private getValidatedSnapshots() {
    return this.snapshots.filter((s) => s.isValidated).sort((a, b) => a.year - b.year)
  }
}
