import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { ContributorViewModel } from '@/contributor/presentation/view-models/Contributor.viewmodel'
import type ContributorRepository from '@/contributor/domain/repositories/Contributor.repository'

export default class WhoAmIPresenter {
  public constructor(private readonly contributorRepository: ContributorRepository) {}

  public handle(): ViewModelContainer<ContributorViewModel> {
    const contributor = this.contributorRepository.getContributor()
    return ViewModelContainer.WithContent({ name: contributor.name })
  }
}
