import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { NumberOfTreesInThisProjectViewModel } from '@/project/presentation/view-models/NumberOfTreesInThisProject.viewmodel'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class HowManyTreesInThisProjectPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public async handle(): Promise<ViewModelContainer<NumberOfTreesInThisProjectViewModel>> {
    const project = this.projectRepository.getProject()

    const onSeveralLine = project.treeCount.toString().length >= 5

    return ViewModelContainer.WithContent({
      plantedByTheClient: project.getFinancedTrees().toPrettyString(),
      totalTrees: project.treeCount.toPrettyString(),
      onSeveralLine,
    })
  }
}
