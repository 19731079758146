const MAX_DECIMAL_NUMBER = 2
const DEFAULT_DECIMAL_SEPARATOR = '.'
const DECIMAL_SEPARATOR = ','
const NO_DECIMAL_THRESHOLD = 100

export function formatForDisplay(number: number): string {
  const everyThreeFigures = /\B(?=(\d{3})+(?!\d))/g
  const addSpaces = (s: string) => s.replace(everyThreeFigures, ' ')
  const ignoreDecimals = number >= NO_DECIMAL_THRESHOLD

  if (ignoreDecimals) {
    return addSpaces(Math.round(number).toString())
  } else {
    const noDecimal = Number.isInteger(number)
    return noDecimal
      ? addSpaces(number.toString())
      : number
          .toFixed(MAX_DECIMAL_NUMBER)
          .replace(DEFAULT_DECIMAL_SEPARATOR, DECIMAL_SEPARATOR)
          .replace(',00', '')
  }
}
