export type HttpMethod = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'
import * as Sentry from '@sentry/browser'

export abstract class FetchGateway {
  public abstract baseUrl: string
  private readonly TIMEOUT_IN_MS = 10_000

  public async fetch<T>(
    path: string,
    options: { method?: HttpMethod; accessToken?: string; body?: any; contentType?: string },
  ): Promise<T | NoResult> {
    const abortController = new AbortController()
    const timeoutId = this.abortFetchWhenTimeoutIsReached(abortController)

    const fetchOptions: RequestInit = {
      method: options.method ?? 'GET',
      signal: abortController.signal,
    }

    const headers = new Headers()
    headers.set('Content-Type', options.contentType ?? 'application/json')
    headers.set('X-Correlation-Id', randomId())
    headers.set('X-Request-Id', randomId())

    if (options.accessToken) {
      headers.set('Authorization', `Bearer ${options.accessToken}`)
    }

    fetchOptions.headers = headers

    if (options.body) {
      fetchOptions.body = JSON.stringify(options.body)
    }

    try {
      const response = await fetch(this.baseUrl + path, fetchOptions)
      if (response.ok) {
        return (await response.json()) as T
      } else {
        return NoResult.ko(fetchOptions.method!, path, response.status)
      }
    } catch (e: any) {
      Sentry.captureException(e)
      if (e instanceof Error) {
        return NoResult.unexpected(fetchOptions.method!, path, e.message)
      } else {
        return NoResult.unknown(fetchOptions.method!, path, e.toString())
      }
    } finally {
      clearTimeout(timeoutId)
    }
  }

  private abortFetchWhenTimeoutIsReached(abortController: AbortController) {
    return setTimeout(
      () => abortController.abort(new TimeoutError(this.TIMEOUT_IN_MS)),
      this.TIMEOUT_IN_MS,
    )
  }
}

function randomId(): string {
  return btoa(Math.random().toString()).substring(0, 12)
}

export class NoResult extends Error {
  private constructor(
    public readonly method: string,
    public readonly path: string,
    public readonly statusCode?: number,
    public readonly reason?: string,
  ) {
    const msg = `Error when calling "${method} ${path}": ${statusCode}`
    if (!statusCode || statusCode >= 500) {
      if (reason) console.error(`${msg}, reason: ${reason}`)
      else console.error(msg)
    }
    super(msg)
  }

  public static ko(method: string, path: string, statusCode: number): NoResult {
    return new NoResult(method, path, statusCode)
  }

  public static unexpected(method: string, path: string, reason: string): NoResult {
    return new NoResult(method, path, undefined, reason)
  }

  public static unknown(method: string, path: string, reason: string): NoResult {
    return new NoResult(method, path, undefined, reason)
  }

  public isUnauthorized(): boolean {
    return this.statusCode !== undefined && this.statusCode === 401
  }
}

class TimeoutError extends Error {
  public constructor(timeout: number) {
    super(`Timeout (${timeout}ms) reached`)
  }
}
