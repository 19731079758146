import type { ProjectType } from '@/project/domain/entities/DetailedProject'

export default class DateOfNextFieldMonitoring {
  public value: Date

  public constructor(
    private readonly plantingEndDate: Date,
    private readonly projectType: ProjectType,
  ) {
    if (this.projectType === 'ECOSYSTEM_RESTORATION_PROJECT_OUTSIDE_EU_US') {
      const date = new Date(this.plantingEndDate)
      const nextDateFieldMonitoring = date.setUTCMonth(this.plantingEndDate.getUTCMonth() + 3)
      this.value = new Date(nextDateFieldMonitoring)
      return
    }

    if (
      this.projectType === 'PLANTATION_PROJECT_EU_US' ||
      this.projectType === 'ASSISTED_NATURAL_REGENERATION_EU_US'
    ) {
      const date = new Date(this.plantingEndDate)
      const nextDateFieldMonitoring = date.setFullYear(this.plantingEndDate.getFullYear() + 3)
      this.value = new Date(nextDateFieldMonitoring)
      return
    }

    if (
      this.projectType === 'LABEL_BAS_CARBON_PROJECT' ||
      this.projectType === 'URBAN_FOREST_PROJECT'
    ) {
      const date = new Date(this.plantingEndDate)
      const nextDateFieldMonitoring = date.setFullYear(this.plantingEndDate.getFullYear() + 2)
      this.value = new Date(nextDateFieldMonitoring)
      return
    }

    const date = new Date(this.plantingEndDate)
    const nextDateFieldMonitoring = date.setFullYear(this.plantingEndDate.getFullYear() + 1)
    this.value = new Date(nextDateFieldMonitoring)
  }
}
