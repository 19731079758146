import type { DetailedProject } from '@/project/domain/entities/DetailedProject'

export class LivingBioMassCarbonMetricsValues {
  public readonly value: number[]

  public constructor(project: DetailedProject) {
    this.value = this.computeLivingBioMassCarbonMetricsValues(project)
  }

  private computeLivingBioMassCarbonMetricsValues(project: DetailedProject): number[] {
    const livingBioMassCarbonMetrics = project.getLivingBioMassCarbonMetrics()

    if (livingBioMassCarbonMetrics.length === 0) return []

    const values: number[] = Array(30).fill(undefined)
    const projectStartingDate = parseInt(project.plantingSeason.split('-')[0])

    project.getLivingBioMassCarbonMetrics().forEach((metricData) => {
      let index = this.computeProjectYear(metricData.aggregate) - projectStartingDate
      if (index < 0) index = 0
      values[index] = metricData.value
    })

    return values
  }

  private computeProjectYear(date: string): number {
    return new Date(date).getFullYear()
  }
}
