import type { TotalBiodiversityImpact } from '@/shared/domain/value-objects/TotalBiodiversityImpact'
import type { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'

export class FinancedBiodiversityImpact {
  public value: number

  public constructor(
    totalBiodiversityImpact: TotalBiodiversityImpact,
    financedRatio: FinancedRatio,
  ) {
    this.value = totalBiodiversityImpact.value * financedRatio.value
  }
}
