import ProjectScenario from '@/project/domain/value-objects/ProjectScenario'
import type { FisProjectId } from '@/shared/domain/Ids'

export default class BiodiversitySimulation {
  public constructor(
    public readonly fisProjectId: FisProjectId,
    public readonly bd1SpeciesLevelSupport: number[],
    public readonly bd2StandStructuralAndBiologicalDiversity: number[],
    public readonly bd3HabitatsAndResourcesMaintainedOrCreated: number[],
    public readonly bd4WorksImpact: number[],
    private readonly projectScenario: ProjectScenario,
  ) {}

  public getProjectScenario(): number[] {
    return this.projectScenario.value
  }

  public static hydrate(rawBiodiversitySimulation: BiodiversitySimulation): BiodiversitySimulation {
    return new BiodiversitySimulation(
      rawBiodiversitySimulation.fisProjectId,
      rawBiodiversitySimulation.bd1SpeciesLevelSupport,
      rawBiodiversitySimulation.bd2StandStructuralAndBiologicalDiversity,
      rawBiodiversitySimulation.bd3HabitatsAndResourcesMaintainedOrCreated,
      rawBiodiversitySimulation.bd4WorksImpact,
      new ProjectScenario(
        rawBiodiversitySimulation.bd1SpeciesLevelSupport,
        rawBiodiversitySimulation.bd2StandStructuralAndBiologicalDiversity,
        rawBiodiversitySimulation.bd3HabitatsAndResourcesMaintainedOrCreated,
        rawBiodiversitySimulation.bd4WorksImpact,
      ),
    )
  }
}
