import { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { BiomeProjectId, CarbonSimulatorProjectId } from '@/shared/domain/Ids'

export type ProjectStatutInProgress = 0
export type ProjectStatutValidated = 1
export type ProjectStatutArchived = 'ARCHIVED'
export type ProjectStatutUnarchived = 'UNARCHIVED'
export type ProjectStatutType = ProjectStatutInProgress | ProjectStatutValidated

export enum ProjectStatut {
  IN_PROGRESS = 0,
  VALIDATED = 1,
  ARCHIVED = 'ARCHIVED',
  UNARCHIVED = 'UNARCHIVED',
}

export type ProjectsApiResponse = {
  results: {
    _id: CarbonSimulatorProjectId
    id: BiomeProjectId
    city: string
    country: string
    statut: ProjectStatutType
    archived?: {
      status: ProjectStatutArchived | ProjectStatutUnarchived
    }
  }[]
}

export type CarbonSimulatorScenarioApiResponse = {
  results: {
    /**
     * Contrairement à ce que le nom de ce champ suggère, totalCredits contient bien le total carbone capturé en tonne de CO2
     */
    totalCredits: number[]
  }
}

export default interface CarbonSimulatorGateway {
  getAllProjects(): Promise<ProjectsApiResponse | NoResult>
  getValidatedProjects(projectIds: BiomeProjectId[]): Promise<ProjectsApiResponse | NoResult>
  getScenario(
    carbonSimulationProjectId: CarbonSimulatorProjectId,
  ): Promise<CarbonSimulatorScenarioApiResponse | NoResult>
}
