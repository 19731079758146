import type { CarbonSimulationDiscount } from '@/shared/domain/value-objects/CarbonSimulationDiscount'
import { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import type { Season } from '@/contributor/domain/entities/Contributor'

export const CLIMATE_SIMULATION_DURATION_IN_YEARS = 30

export class CarbonSimulation {
  public constructor(
    public readonly simulationValues: number[],
    public readonly startingYear: StartingYear,
    public readonly discount: CarbonSimulationDiscount,
    public readonly financedRatio: FinancedRatio,
  ) {}

  public rawValues(): number[] {
    return this.simulationValues.map((v) => this.applyDiscount(v))
  }

  public financedValues(): number[] {
    return this.rawValues().map((v) => this.applyFinancedRatio(v))
  }

  public raw(index: number = CLIMATE_SIMULATION_DURATION_IN_YEARS): number {
    const atIndex = this.simulationValues.at(index)
    const last = this.simulationValues.last()
    return this.applyDiscount(atIndex ?? last ?? 0)
  }

  public financed(index: number = CLIMATE_SIMULATION_DURATION_IN_YEARS): number {
    return this.applyFinancedRatio(this.raw(index))
  }

  public simulatedYears(): number[] {
    return Array.from({ length: CLIMATE_SIMULATION_DURATION_IN_YEARS }).map(
      (_, i) => this.startingYear.value + i,
    )
  }

  public isSimulatedAtYear(year: number): boolean {
    return this.startingYear.value.isBefore(year)
  }

  private applyFinancedRatio(n: number): number {
    return (n * this.financedRatio.value).toFixed(12).toNumber()
  }

  private applyDiscount(n: number): number {
    const discounted = n * this.discount.value
    return (n - discounted).toFixed(12).toNumber()
  }
}

export class StartingYear {
  private constructor(public readonly value: number) {}

  public static fromPlantingSeason(plantingSeason: Season): StartingYear {
    const rawStartingSeason = plantingSeason.split('-').at(0)
    if (!rawStartingSeason) throw new Error(`Cannot parse planting season: ${plantingSeason}`)
    const value = parseInt(rawStartingSeason, 10)
    return new StartingYear(value)
  }
}
