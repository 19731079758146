import type { BiomeProjectId } from '@/shared/domain/Ids'
import { Description } from '@/shared/domain/value-objects/Descriptions'
import { ContributorTotalFinancedSequesteredCarbon } from '@/contributor/domain/value-objects/ContributorTotalFinancedSequesteredCarbon'
import { SummarizedProject } from '@/contributor/domain/entities/SummarizedProject'
import ContributorTotalFinancedBiodiversityImpact from '@/contributor/domain/value-objects/ContributorTotalFinancedBiodiversityImpact'

export type Country = string & { _: 'country' }
export type Season = string & { _: 'season' }

export default class Contributor {
  public constructor(
    public readonly name: string,
    public readonly logo: string,
    public readonly description: Description,
    private readonly contributorTotalFinancedSequesteredCarbon: ContributorTotalFinancedSequesteredCarbon,
    private readonly contributorTotalFinancedBiodiversityImpact: ContributorTotalFinancedBiodiversityImpact,
    public readonly summarizedProjects: SummarizedProject[],
  ) {}

  public static create(
    name: string,
    logo: string,
    description: Description,
    contributorTotalSequesteredCarbon: ContributorTotalFinancedSequesteredCarbon,
    contributorTotalFinancedBiodiversityImpact: ContributorTotalFinancedBiodiversityImpact,
    projects: SummarizedProject[],
  ): Contributor {
    return new Contributor(
      name,
      logo,
      description,
      contributorTotalSequesteredCarbon,
      contributorTotalFinancedBiodiversityImpact,
      projects,
    )
  }

  public static hydrate(contributor: Contributor): Contributor {
    return new Contributor(
      contributor.name,
      contributor.logo,
      contributor.description,
      contributor.contributorTotalFinancedSequesteredCarbon,
      contributor.contributorTotalFinancedBiodiversityImpact,
      contributor.summarizedProjects.map(
        (p) =>
          new SummarizedProject(
            p.fisId,
            p.biomeId,
            p.name,
            p.headerImage,
            p.location,
            p.plantingSeason,
            p.typologies,
            p.area,
            p.treeCount,
            p.carbonSimulation,
            p.biodiversitySimulations,
            p.financedRatio,
            p.financedPercentage,
            p.totalBiodiversityImpact,
            p.financedBiodiversityImpact,
            p.financedTrees,
            p.financedArea,
          ),
      ),
    )
  }

  public getSummarizedProject(biomeProjectId: BiomeProjectId): SummarizedProject {
    return this.summarizedProjects.find((p) => p.biomeId === biomeProjectId)!
  }

  public numberOfProjectWithCarbonSimulation(): number {
    return this.summarizedProjects.filter((p) => p.containsCarbonSimulations()).length
  }
  public totalFinancedSequesteredCarbon(): number {
    return this.contributorTotalFinancedSequesteredCarbon.value
  }

  public totalFinancedBiodiversityImpact(): number {
    return this.contributorTotalFinancedBiodiversityImpact.value
  }

  public numberOfProjectWithBiodiversitySimulation(): number {
    return this.summarizedProjects.filter((p) => p.containsBiodiversitySimulation()).length
  }

  public totalBiodiversityImpact(): number {
    return this.summarizedProjects.map((p) => p.getTotalBiodiversityImpact()).sum()
  }
}
