import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { BiodiversityImpactProjectionChartViewModel } from '@/project/presentation/view-models/BiodiversityImpactProjectionChart.viewmodel'
import { Unit } from '@/shared/domain/value-objects/Units'
import { getTranslationKeyForUnit } from '@/shared/presentation/translator/Unit.translator'
import type { DetailedProject } from '@/project/domain/entities/DetailedProject'

export default class WhatIsMyBiodiversityImpactOverThirtyYearsPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<BiodiversityImpactProjectionChartViewModel> {
    const project = this.projectRepository.getProject()

    if (project.biodiversitySimulation === null) {
      return ViewModelContainer.WithError(new NoBiodiversitySimulationAvailable())
    }

    return ViewModelContainer.WithContent(<BiodiversityImpactProjectionChartViewModel>{
      chart: {
        numberOfYears: this.computeOver30NextYears(31, project),
        ordinateUnit: getTranslationKeyForUnit(Unit.SCORE),
        bd1: project.biodiversitySimulation.bd1SpeciesLevelSupport,
        bd2: project.biodiversitySimulation.bd2StandStructuralAndBiologicalDiversity,
        bd3: project.biodiversitySimulation.bd3HabitatsAndResourcesMaintainedOrCreated,
        bd4: project.biodiversitySimulation.bd4WorksImpact,
        projectScenario: project.biodiversitySimulation.getProjectScenario(),
      },
      measure: {
        current: project.getFinancedBiodiversityImpact() ?? 0,
        total: project.getTotalBiodiversityImpactScore() ?? 0,
        unit: getTranslationKeyForUnit(
          Unit.BIODIVERSITY_RESTORATION_HECTARE_EQUIVALENT_REFERENCE_ECOSYSTEM,
        ),
      },
    })
  }

  private computeOver30NextYears(length: number, project: DetailedProject) {
    const projectStartingDate = parseInt(project.plantingSeason.split('-')[0])

    return Array(length)
      .fill(0)
      .map((_, index) => projectStartingDate + index)
  }
}

class NoBiodiversitySimulationAvailable extends Error {
  public constructor() {
    super('projectPage.impactProjection.biodiversity.error.noSimulation')
  }
}
