import type { ProjectBiodiversitySimulation } from '@/shared/domain/value-objects/ProjectBiodiversitySimulation'
import type { BiodiversitySimulationResults } from '@/shared/infrastructure/gateways/monolith.gateway'

export const BIODIVERSITY_SIMULATION_DURATION_IN_YEARS = 30

export default class ContributorTotalFinancedBiodiversityImpact {
  public value: number

  public constructor(
    private readonly simulations: ProjectBiodiversitySimulation[],
    yearIndex: number = BIODIVERSITY_SIMULATION_DURATION_IN_YEARS,
  ) {
    this.value = this.simulations
      .map((s) => {
        const bd1 = this.atIndexOrLastOr0(s, 'bd1SpeciesLevelSupport', yearIndex)
        const bd2 = this.atIndexOrLastOr0(s, 'bd2StandStructuralAndBiologicalDiversity', yearIndex)
        const bd3 = this.atIndexOrLastOr0(
          s,
          'bd3HabitatsAndResourcesMaintainedOrCreated',
          yearIndex,
        )
        const bd4 = this.atIndexOrLastOr0(s, 'bd4WorksImpact', yearIndex)

        const impact = bd1 + bd2 + bd3 + bd4
        const total = impact * s.area
        return total * s.financedRatio.value
      })
      .sum()
  }

  private atIndexOrLastOr0(
    bsr: BiodiversitySimulationResults,
    field: keyof BiodiversitySimulationResults,
    year: number,
  ): number {
    return bsr[field].at(year) ?? bsr[field].at(-1) ?? 0
  }
}
