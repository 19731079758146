import { FetchGateway, NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { FisProjectId } from '@/shared/domain/Ids'
import type { RemoteSensingGateway } from '@/project/infrastructure/gateways/RemoteSensing.gateway'

export type IndexResult = {
  id: string
  snapshotId: string
  url: string
  createdAt: Date
  minimum: number
  mean: number
  maximum: number
}

export type PlotImageResult = {
  id: string
  fisId: string
  snapshotId: string
  stringRepresentation: string
  status: string
  url: string
  satellitePhotoDate: Date
  externalId: string
  externalName: string
}

export type SnapshotResult = {
  id: string
  projectId: FisProjectId
  startDate: string
  endDate: string
  format: string
  status: string
  url: string
  ndvis: IndexResult[]
  ndmis: IndexResult[]
  plotImages: PlotImageResult[]
  year: number
  isValidated: number
}

export type RemoteSensingIndexesResults = {
  id: string
  fisId: FisProjectId
  snapshots: SnapshotResult[]
  spectralSpecies: SpectralSpeciesResults[]
}

interface SpectralSpeciesParameters {
  id: string
  spectralSpeciesId: string
  ndviBareSoilThreshold: number
  selectedPrincipalComponents: number[]
  pcaDataset: string
  pcaTableAsCsv: string
  maximumVegetalSpeciesNumberInForestStand: number
  optimalPredictedClassesNumber: number
  createdAt: Date
  updatedAt: Date
}

interface SpectralSpeciesStep {
  id: string
  spectralSpeciesId: string
  step: number
  status: string
  urls: string[]
  createdAt: Date
  updatedAt: Date
}

export type SpectralSpeciesResults = {
  id: string
  snapshotId: string
  parameters: SpectralSpeciesParameters
  steps: SpectralSpeciesStep[]
}

export default class RemoteSensingHttpGateway extends FetchGateway implements RemoteSensingGateway {
  public baseUrl: string

  public constructor(_baseUrl: string) {
    super()

    this.baseUrl = _baseUrl
  }

  public async getIndexes(id: FisProjectId): Promise<NoResult | RemoteSensingIndexesResults> {
    const response = await this.fetch<RemoteSensingIndexesResults>('/projects/' + id, {
      method: 'GET',
    })
    if (response instanceof NoResult) return response
    else {
      const snapshotsWithDownloadableUrl: SnapshotResult[] = []
      for await (const s of response.snapshots) {
        const ndvisWithDownloadableUrl: IndexResult[] = []
        const ndmisWithDownloadableUrl: IndexResult[] = []
        for await (const n of s.ndvis) {
          const newUrl = await this.getDownloadableUrl(n.id + '.' + s.format.toLowerCase())
          ndvisWithDownloadableUrl.push({
            ...n,
            url: newUrl instanceof NoResult ? n.url : newUrl,
          })
        }
        for await (const n of s.ndmis) {
          const newUrl = await this.getDownloadableUrl(n.id + '.' + s.format.toLowerCase())
          ndmisWithDownloadableUrl.push({
            ...n,
            url: newUrl instanceof NoResult ? n.url : newUrl,
          })
        }
        snapshotsWithDownloadableUrl.push({
          ...s,
          ndvis: ndvisWithDownloadableUrl,
          ndmis: ndmisWithDownloadableUrl,
        })
      }

      for await (const ss of response.spectralSpecies) {
        const lastStep = ss.steps.find((step) => step.step === 3 && step.status === 'COMPLETE')

        if (lastStep) {
          const lastStepUrl = await this.getDownloadableUrl(
            this.getSpectralSpecieIdForDownloableUrl(ss.id, lastStep.urls[0]),
          )

          lastStep.urls = lastStepUrl instanceof NoResult ? [lastStep.urls[0]] : [lastStepUrl]
        }
      }

      return {
        id: response.id,
        fisId: response.fisId,
        snapshots: snapshotsWithDownloadableUrl,
        spectralSpecies: response.spectralSpecies,
      }
    }
  }

  private async getDownloadableUrl(snapshotId: string): Promise<NoResult | string> {
    return await this.fetch('/snapshots/' + snapshotId + '/downloadable-url', { method: 'GET' })
  }

  private getSpectralSpecieIdForDownloableUrl(spectralSpecieId: string, url: string): string {
    const imageURL = new URL(url)
    const filename = imageURL.pathname.split(`${spectralSpecieId}-`)[1]
    return `${spectralSpecieId}-${filename}`
  }
}
