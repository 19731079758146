export class ViewModelContainer<T> {
  public readonly loading

  public static Loading<T>(): ViewModelContainer<T> {
    return new ViewModelContainer<T>()
  }

  public static WithContent<T>(content: T): ViewModelContainer<T> {
    return new ViewModelContainer<T>(content)
  }

  public static WithError<T>(error: Error): ViewModelContainer<T> {
    return new ViewModelContainer<T>(error)
  }

  private constructor(private content?: T | Error) {
    this.loading = content === undefined
  }

  public getError(): Error {
    if (this.content instanceof Error) {
      return this.content as Error
    } else {
      throw new Error("Can't access to viewmodel error since viewmodel has no error.")
    }
  }

  public getContent(): T {
    if (!(this.content instanceof Error)) {
      return this.content as T
    } else {
      throw new Error("Can't access to viewmodel content since viewmodel is in error state.")
    }
  }

  public isLoading(): boolean {
    return this.loading
  }

  public isError(): boolean {
    return this.content instanceof Error
  }

  public isReady(): boolean {
    return !this.isError() && !this.isLoading()
  }
}
