import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type StorageService from '@/shared/infrastructure/services/Storage.service'
import type { AuthenticatedUser } from '@/authentication/infrastructure/services/Authentication.service'
import { NoItemFound } from '@/shared/infrastructure/services/Storage.service'
import type { AnnualReportViewModel } from '@/contributor/presentation/view-models/AnnualReport.viewmodel'

export default class WhereICanSeeMyAnnualReportPresenter {
  public constructor(private readonly storageService: StorageService) {}

  public handle(): ViewModelContainer<AnnualReportViewModel> {
    const user = this.storageService.get<AuthenticatedUser>('user')
    if (user instanceof NoItemFound) return ViewModelContainer.WithError(new NoUserFound())

    if (user.email.includes('moethennessy')) {
      return ViewModelContainer.WithContent({
        url: 'https://annual-report.s3-website.fr-par.scw.cloud/RapportClient_Hennessy-2021-2022_20230411_VF.pdf',
      })
    }
    if (user.email.includes('kiabi')) {
      return ViewModelContainer.WithContent({
        url: 'https://annual-report.s3-website.fr-par.scw.cloud/RapportClient_Kiabi_2021-2023_20230619_VF.pdf',
      })
    }

    if (user.email.includes('ca-assurances')) {
      return ViewModelContainer.WithContent({
        url: 'https://annual-report.s3-website.fr-par.scw.cloud/RapportClient_CAA_VF_260523.pdf',
      })
    }

    return ViewModelContainer.WithError(new NoAnnualReportAvailable())
  }
}

class NoUserFound extends Error {
  public constructor() {
    super('contributorPage.annualReport.error.noUserFound')
  }
}

class NoAnnualReportAvailable extends Error {
  public constructor() {
    super('contributorPage.annualReport.error.noAnnualReportAvailable')
  }
}
