import type { ClimateImpactProjectionChartViewModel } from '@/project/presentation/view-models/ClimateImpactProjectionChart.viewmodel'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import { Unit } from '@/shared/domain/value-objects/Units'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import { getTranslationKeyForUnit } from '@/shared/presentation/translator/Unit.translator'
import { LivingBioMassCarbonMetricsValues } from '@/project/domain/value-objects/LivingBioMassCarbonMetricsValues'
import type { DetailedProject } from '@/project/domain/entities/DetailedProject'

export default class HowManyCarbonISequesteredOverThirtyYearsPresenter {
  private YEAR_0_TO_30 = 31

  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<ClimateImpactProjectionChartViewModel> {
    const project = this.projectRepository.getProject()

    return ViewModelContainer.WithContent({
      hasSimulation: project.containsCarbonSimulation(),
      sequesteredCarbonOverThirtyYearsSeries: {
        numberOfYears: this.computeOver30NextYears(this.YEAR_0_TO_30, project),
        forTheProject: project.getSequesteredCarbonOver30Years(),
        percentageFinancedByTheContributor: project.getFinancedSequesteredCarbonOver30Years(),
      },
      sequesteredCarbon: {
        percentageFinancedByTheContributor: project.getFinancedSequesteredCarbon().toPrettyString(),
        total: project.getTotalSequesteredCarbon().toPrettyString(),
        unit: getTranslationKeyForUnit(Unit.TONS_CO2_EQUIVALENT),
      },
      livingBioMassCarbonMetrics: new LivingBioMassCarbonMetricsValues(project).value,
    })
  }

  private computeOver30NextYears(length: number, project: DetailedProject) {
    const projectStartingDate = parseInt(project.plantingSeason.split('-')[0])

    return Array(length)
      .fill(0)
      .map((_, index) => projectStartingDate + index)
  }
}
