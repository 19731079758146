import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { ProjectStatusViewModel } from '@/project/presentation/view-models/ProjectStatus.viewmodel'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'

export default class WhatIsMyProjectStatusPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<ProjectStatusViewModel> {
    const project = this.projectRepository.getProject()

    if (project.hasContributorFullyFinancedProject()) return this.followUp()

    return this.toFinance(
      /*
        FIXME:
         On ne doit pas utiliser le % de financement du contributeur ici (getFinancedPercentage).
         On doit utiliser à la place de % de financement issus de TOUS les contributeurs
         Ce calcul n'existe pas actuellement.
       */
      project.getFinancedPercentage(),
      project.getResidualFinancementPercentage(),
    )
  }

  private followUp() {
    return ViewModelContainer.WithContent({
      label: 'projectPage.generalInformation.status.label.followUp',
      description: 'projectPage.generalInformation.status.description.followUp',
      progressBarValue: 100,
      percentage: '100',
      percentageLabel: 'projectPage.generalInformation.status.percentageLabel.followUp',
    })
  }

  private toFinance(contributionPercentage: number, residualFinancementPercentage: number) {
    return ViewModelContainer.WithContent({
      label: 'projectPage.generalInformation.status.label.toFinance',
      description: 'projectPage.generalInformation.status.description.toFinance',
      progressBarValue: contributionPercentage,
      percentage: residualFinancementPercentage.toPrettyString(),
      percentageLabel: 'projectPage.generalInformation.status.percentageLabel.toFinance',
    })
  }
}
