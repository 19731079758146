import type { BiomeProjectId, FisProjectId } from '@/shared/domain/Ids'
import { FinancedRatio } from '@/shared/domain/value-objects/FinancedRatio'
import type { Coordinates } from '@/shared/domain/Coordinates'
import type { FinancedArea } from '@/shared/domain/value-objects/FinancedArea'
import type { FinancedTrees } from '@/shared/domain/value-objects/FinancedTrees'
import type { FinancedPercentage } from '@/shared/domain/value-objects/FinancedPercentage'
import type { TotalBiodiversityImpact } from '@/shared/domain/value-objects/TotalBiodiversityImpact'
import type { FinancedBiodiversityImpact } from '@/shared/domain/value-objects/FinancedBiodiversityImpact'
import { type CarbonSimulation } from '@/shared/domain/value-objects/CarbonSimulation'
import type { Country, Season } from '@/contributor/domain/entities/Contributor'
import type { BiodiversitySimulationResults } from '@/shared/infrastructure/gateways/monolith.gateway'
import ContributorTotalFinancedBiodiversityImpact, {
  BIODIVERSITY_SIMULATION_DURATION_IN_YEARS,
} from '@/contributor/domain/value-objects/ContributorTotalFinancedBiodiversityImpact'
import type { Hectare } from '@/shared/domain/value-objects/Units'

export class SummarizedProject {
  public constructor(
    public readonly fisId: FisProjectId,
    public readonly biomeId: BiomeProjectId,
    public readonly name: string,
    public readonly headerImage: string,
    public readonly location: { city: string; country: Country; continent: string } & Coordinates,
    public readonly plantingSeason: Season,
    public readonly typologies: { main: string; secondaries: string[] },
    public readonly area: Hectare,
    public readonly treeCount: number,
    public readonly carbonSimulation: CarbonSimulation | null,
    public readonly biodiversitySimulations: BiodiversitySimulationResults[],
    public readonly financedRatio: FinancedRatio,
    public readonly financedPercentage: FinancedPercentage,
    public readonly totalBiodiversityImpact: TotalBiodiversityImpact | null,
    public readonly financedBiodiversityImpact: FinancedBiodiversityImpact | null,
    public readonly financedTrees: FinancedTrees,
    public readonly financedArea: FinancedArea,
  ) {}

  public countFinancedSimulatedSequesteredCarbon(year?: number): number {
    if (!this.carbonSimulation) {
      return 0
    }

    if (!year) {
      return this.carbonSimulation.financed()
    }

    if (year.isBefore(this.getFirstYear(), true)) {
      return 0
    }

    const yearIndex = year - this.getFirstYear()
    return this.carbonSimulation.financed(yearIndex)
  }

  public countFinancedSimulatedBiodiversityImpact(year?: number): number {
    if (!this.containsBiodiversitySimulation()) {
      return 0
    }

    if (!year) {
      return this.financedBiodiversityImpact ? this.financedBiodiversityImpact.value : 0
    }

    if (year.isBefore(this.getFirstYear(), true)) {
      return 0
    }

    const yearIndex = year - this.getFirstYear()
    const impact = new ContributorTotalFinancedBiodiversityImpact(
      this.biodiversitySimulations.map((bs) => ({
        financedRatio: this.financedRatio,
        area: this.area,
        bd1SpeciesLevelSupport: bs.bd1SpeciesLevelSupport,
        bd2StandStructuralAndBiologicalDiversity: bs.bd2StandStructuralAndBiologicalDiversity,
        bd3HabitatsAndResourcesMaintainedOrCreated: bs.bd3HabitatsAndResourcesMaintainedOrCreated,
        bd4WorksImpact: bs.bd4WorksImpact,
      })),
      yearIndex,
    )
    return impact.value
  }

  public getTotalSimulatedSequesteredCarbon(): number {
    return this.carbonSimulation?.raw() ?? 0
  }

  public getFinancedArea(): Hectare {
    return this.financedArea.value
  }

  public getFinancedTrees(): number {
    return this.financedTrees.value
  }

  public getFinancedPercentage(): number {
    return this.financedPercentage.value
  }

  public getTotalBiodiversityImpact(): number {
    return this.totalBiodiversityImpact ? this.totalBiodiversityImpact.value : 0
  }

  public getFinancedBiodiversityImpact(): number {
    return this.financedBiodiversityImpact ? this.financedBiodiversityImpact.value : 0
  }

  public containsCarbonSimulations(): boolean {
    return this.carbonSimulation ? this.carbonSimulation?.simulationValues.isNotEmpty() : false
  }

  public containsBiodiversitySimulation(): boolean {
    return this.totalBiodiversityImpact !== null
  }

  public listSimulatedYearsForCarbon(): number[] {
    return this.carbonSimulation?.simulatedYears() ?? []
  }

  public listSimulatedYearsForBiodiversity(): number[] {
    if (this.biodiversitySimulations.isEmpty()) return []

    const startingSeason = this.getFirstYear()
    const simulationYearOffsets = Array.from(
      { length: BIODIVERSITY_SIMULATION_DURATION_IN_YEARS },
      (_, i) => i,
    )
    return simulationYearOffsets.map((offset) => startingSeason + offset)
  }

  public isSimulatedForCarbonAtYear(year: number): boolean {
    return this.carbonSimulation?.isSimulatedAtYear(year) ?? false
  }

  public isSimulatedForBiodiversityAtYear(year: number): boolean {
    if (this.listSimulatedYearsForBiodiversity().isEmpty()) {
      return false
    }

    if (year.isBefore(this.getFirstYear(), true)) {
      return false
    }

    return true
  }

  private getFirstYear() {
    const rawStartingSeason = this.plantingSeason.split('-').at(0)
    if (!rawStartingSeason) throw new Error('Invalid plantingSeason')
    return parseInt(rawStartingSeason, 10)
  }
}
