import { latLng } from 'leaflet'
import { ViewModelContainer } from '@/shared/presentation/presenters/ViewModelContainer'
import type { PolygonsMapViewModel } from '@/project/presentation/view-models/PolygonsMap.viewmodel'
import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import constants from '@ds/styles/constants'

export default class WhereIsLocatedMyProjectPresenter {
  public constructor(private readonly projectRepository: DetailedProjectRepository) {}

  public handle(): ViewModelContainer<PolygonsMapViewModel> {
    const project = this.projectRepository.getProject()
    return ViewModelContainer.WithContent({
      color: constants.colors.greenLight,
      polygonListOfProject: project.polygons.map((polygon) =>
        polygon.coordinates.map((coordinate) => latLng(coordinate.latitude, coordinate.longitude)),
      ),
    })
  }
}
