export class TotalBiodiversityImpact {
  public value: number

  public constructor(
    private readonly projectArea: number,
    private readonly bd1SpeciesLevelSupport: number[],
    private readonly bd2StandStructuralAndBiologicalDiversity: number[],
    private readonly bd3HabitatsAndResourcesMaintainedOrCreated: number[],
    private readonly bd4WorksImpact: number[],
  ) {
    const year30 = 29
    const impact =
      (this.bd1SpeciesLevelSupport.at(year30) ?? 0) +
      (this.bd2StandStructuralAndBiologicalDiversity.at(year30) ?? 0) +
      (this.bd3HabitatsAndResourcesMaintainedOrCreated.at(year30) ?? 0) +
      (this.bd4WorksImpact.at(year30) ?? 0)
    this.value = impact * this.projectArea
  }
}
